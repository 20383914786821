<!-- 联系 -->
<template>
  <div class="about"> 

    <div class="inspection-banner">
      <a-carousel autoplay arrows effect="fade" dotsClass="security-box-dot">
        <img src="@/assets/img/lxwm.png" alt="banner" />
      </a-carousel>
    </div>

    <div class="about-box">
      <div class="about-title">联系我们</div>
      <div class="about-box1">
        <div style="display: flex; align-items: center">
          <img
            src="@/assets//img/dizhi.png"
            style="width: 42px; height: 42px"
            alt=""
          />
          <div class="box-right">
            <div class="label">
              <span>地址</span>
              <span style="color: #cccccc; margin-left: 10px">Address</span>
            </div>
            <div class="val">长沙市天心区湘府中路369号星城荣域1期综A座1303</div>
          </div>
        </div>
        <div style="display: flex; align-items: center">
          <img
            src="@/assets//img/E-mail.png"
            style="width: 42px; height: 42px"
            alt=""
          />
          <div class="box-right">
            <div class="label">
              <span>邮箱</span>
              <span style="color: #cccccc; margin-left: 10px">E-mail</span>
            </div>
            <div class="val">laotie@laotiechebanghui.com</div>
          </div>
        </div>
        <div style="display: flex; align-items: center">
          <img
            src="@/assets//img/dianhua.png"
            style="width: 42px; height: 42px"
            alt=""
          />
          <div class="box-right">
            <div class="label">
              <span>电话</span>
              <span style="color: #cccccc; margin-left: 10px">Telephone</span>
            </div>
            <div class="val">0731-82232822</div>
          </div>
        </div>
      </div>
    </div>
    <div class="map">
      <div style="height: 700px; width: 100%;" id="map"></div>
    </div> 
  </div>
</template>
  
  <script> 
export default { 
  data() {
    return {};
  }, 
  mounted() {
    /*eslint-disable*/
    var map = new AMap.Map("map", {
      center: [112.988247, 28.111526],
      zoom: 20,
    });
    var marker = new AMap.Marker({
      position: [112.988247, 28.111526], // 标记点的经纬度坐标
      title: "标记点名称", // 标记点的名称
    });

    // 将标记点添加到地图上
    map.add(marker);

    /*eslint-disable*/
  },
};
</script>
  
  <style lang="less">
.inspection-banner {
  position: relative;
  width: 100%;
  // height: 400px;
  overflow: hidden;
  color: #fff;
}
.ant-carousel .security-box-dot li button {
  background: #666 !important;
  border: 1px solid #fff;
}
.about-box {
  width: 1200px;
  margin: 50px auto 0;
  .about-title {
    position: relative;
    text-align: center;
    font-size: 30px;
    &::after {
      content: "";
      position: absolute;
      bottom: -10px;
      left: 50%;
      transform: translate(-50%, 0);
      width: 42px;
      height: 4px;
      background: linear-gradient(270deg, #118ff9 0%, #0756f0 100%);
      border-radius: 2px;
    }
  }
  .box-right {
    margin-left: 10px;
    .label {
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #333333;
    }
    .val {
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #333333;
    }
  }
  .about-box1 {
    margin-top: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
.map { 
  width: 1200px;  
  margin: 50px auto 0;
}
</style>