<template>
  <div class="inspection">
    <div style="background-color: #f6f8fb; padding-bottom: 50px">
      <div class="inspection-banner">
        <a-carousel autoplay arrows effect="fade" dotsClass="security-box-dot">
          <img src="@/assets/banner.jpg" alt="banner" />
        </a-carousel>
      </div>

      <div class="inspection-details">
        <div class="inspection-title">查询附近车检服务网点</div>
        <a-input-search
          v-model="value"
          placeholder="搜索附近车检服务网点"
          enter-button="立即查询"
          size="large"
          @search="onSearch"
        />
      </div>

      <div class="inspection-box">
        <div class="inspection-center">
          <div class="inspection-map" id="map">
            <!-- <img src="@/assets/img/gsjj.png" /> -->
          </div>
          <div class="inspection-bot">
            <div class="label">车帮惠车检服务网点</div>
            <div class="bot">
              <template v-if="list.length">
                <div class="cs" @scroll="handleScroll">
                  <div
                    class="item"
                    v-for="(v, i) in list"
                    :key="i"
                    @click="handleDateil(v)"
                  >
                    <img
                      :src="v.labelPic"
                      style="width: 350px; height: 160px"
                      alt="检查站"
                    />
                    <div class="name">{{ v.name }}</div>
                    <div class="business">
                      <span class="item-label">营业时间：</span>
                      <span class="item-value">{{ v.openTime }}</span>
                    </div>
                    <div class="addr">
                      <span class="item-label">地址：</span>
                      <span class="item-value">{{ v.location }}</span>
                    </div>
                    <div class="contact" v-if="v.phone">
                      <span class="item-label">联系方式：</span>
                      <span class="item-value">{{ v.phone }}</span>
                    </div>
                  </div>
                </div>
              </template>
              <div v-else>
                <a-empty description="暂无数据" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { message } from "ant-design-vue"; 
export default {
  data() {
    return {
      value: "",
      pageIndex: 1,
      page: 0,
      list: [],
      markers: [], 
    };
  },
  created() {
    this.getData();
  },
  mounted() {
    setTimeout(() => {
      this.getmarker();
    }, 500);
  },
  methods: {
    getData(data, loadmore = false) {
      axios
        .get("/admin/departmentofmotorvehicleinfo/pageweb", { params: data })
        .then((res) => {
          this.page = res.data.pages;
          if (loadmore) {
            this.list = this.list.concat(res.data.records);
          } else {
            this.list = res.data.records;
          }
          res.data.records.forEach((element) => {
            this.markers.push({
              position: [element.longitude, element.latitude],
              title: element.name,
            });
          });
        });
    },
    getmarker() {
      /*eslint-disable*/
      let markers = JSON.parse(JSON.stringify(this.markers));
      var map = new AMap.Map("map", {
        center: markers.length
          ? [markers[0].position[0], markers[0].position[1]]
          : [113.00462, 28.16662],
        zoom: 12,
      });

      // 遍历标记点数组，创建并添加标记点到地图上
      for (let i = 0; i < markers.length; i++) {
        let marker = new AMap.Marker({
          position: markers[i].position, // 标记点的经纬度坐标
          title: markers[i].title, // 标记点的名称
        });

        map.add(marker);
      }
      /*eslint-disable*/
    },
    onSearch(v) {
      this.list = [];
      this.markers = [];
      this.getData({ name: v, current: 1 });
      setTimeout(() => {
        this.getmarker();
      }, 500);
    },
    handleDateil(v) {
      let markers = JSON.parse(JSON.stringify(this.markers));
      /*eslint-disable*/
      var map = new AMap.Map("map", {
        center: [v.longitude, v.latitude],
        zoom: 16,
      });

      // 遍历标记点数组，创建并添加标记点到地图上
      for (let i = 0; i < markers.length; i++) {
        let marker = new AMap.Marker({
          position: markers[i].position, // 标记点的经纬度坐标
          title: markers[i].title, // 标记点的名称
        });

        map.add(marker);
      }
      /*eslint-disable*/
    },
    handleScroll(e) {
      if (
        e.target.offsetHeight + e.target.scrollTop - e.target.scrollHeight >= 0 
      ) {
        if (this.pageIndex >= this.page) {
          message.info("没有数据了");
        } else {
          this.pageIndex++;
          this.getData({ current: this.pageIndex }, true);
        }
      }
    },
  },
};
</script>

<style lang="less" scoped>
.inspection-banner {
  position: relative;
  width: 100%;
  // height: 600px;
  overflow: hidden;
  color: #fff;
}
.ant-carousel .security-box-dot li button {
  background: #666 !important;
  border: 1px solid #fff;
}
.inspection-details {
  position: relative;
  margin: -70px auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px 50px;
  background: #fff;
  border-radius: 16px;
  // height: 220px;
  width: 1200px;
  z-index: 1;
  box-shadow: 0px 5px 10px 0px rgba(204, 204, 204, 0.15);
  font-size: 32px;
  color: #012f8a;
  border-radius: 5px;
  .inspection-title {
    margin: 0 0 30px 0;
    font-size: 36px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #222222;
  }
}
.inspection-box {
  margin-top: 100px;
  .inspection-center {
    margin: 0 auto;
    width: 1200px;
    display: flex;
    justify-content: space-between;
    .inspection-map {
      width: 730px;
      height: 700px;
      border-radius: 5px;
      img {
        width: 730px;
        height: 700px;
      }
    }
    .inspection-bot {
      width: 440px;
      // height: 700px;
      background: linear-gradient(0deg, #4083ff, #429bff);
      border-radius: 5px;
      padding: 20px;
      .label {
        font-size: 24px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #ffffff;
      }
      // ::-webkit-scrollbar {
      //   display: none;
      // }
      .bot {
        width: 400px;
        height: 600px;
        margin-top: 20px;
        background: #ffffff;
        box-shadow: 0px 5px 10px 0px rgba(204, 204, 204, 0.15);
        padding: 20px;
        .cs {
          height: 560px;
          overflow: hidden;
          overflow-y: scroll;
        }
        .item {
          margin-bottom: 20px;
          border-bottom: 1px solid #e2e2e2;
          // height: 290px;
          padding-bottom: 10px;
          cursor: pointer;
          .name {
            font-size: 18px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #397bff;
            margin: 10px 0;
          }
          .item-label {
            font-size: 12px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #222;
          }
          .item-value {
            font-size: 12px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #666666;
          }
        }
      }
    }
  }
}
</style>