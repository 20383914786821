<template>
  <div class="commerce">
    <Header />
    <div class="commerce-top">
      <div class="commerce-topBox">

        <div class="commerce-top--box">
          <div class="commerce-top__left">
            <!-- <img src="logo3.png" style="width: 194px"/> -->
          </div>
          <div class="commerce-top__right">
            <img src="@/assets/img/DSPHERE.png"/>
            <p>数影星球（杭州）科技有限公司</p>
          </div>
        </div>
        <div class="commerce-mil--box">
          <img src="@/assets/img/dsgzkj.png" style="width: 710px"/>
          <div class="commerce-mil--box__span">
            <div class="commerce-mil--box__span-gang"></div>
            2021
            <div class="commerce-mil--box__span-gang"></div>
          </div>
        </div>
        <div class="commerce-ps--box">
          <img src="@/assets/img/commercegang.png" class="commerce-ps--box__gang" />
          老铁车帮惠能让您随时随地，<span>高效、安全、智能</span>的管理您的店铺
        </div>
        <div class="commerce-ps--button">
          <a-button @click="$router.push('/contact_us')">立即体验</a-button>
        </div>
      </div>
    </div>
    <div class="commerce-next">
      <div class="commerce-problem--box">
        您是否也有这些困扰
      </div>
      <div class="commerce-problem--list">
        <div class="commerce-problem--list-item1">
          店群运营
        </div>
        <div class="commerce-problem--list-item2">
          账号管理
        </div>
        <div class="commerce-problem--list-item3">
          远程办公
        </div>
      </div>
      <div class="commerce-problem--text">
        <div class="commerce-problem--text-item">
          <div class="commerce-problem--text-dot"></div>
          同时运营多家店铺，关注店铺的合规安全，担心被封
        </div>
        <div class="commerce-problem--text-item">
          <div class="commerce-problem--text-dot"></div>
          自购VPS，多个远程桌面操作麻烦，体验割裂
        </div>
        <div class="commerce-problem--text-item">
          <div class="commerce-problem--text-dot"></div>
          账号太多不好记，小心翼翼生怕在VPS里登错账号
        </div>
        <div class="commerce-problem--text-item">
          <div class="commerce-problem--text-dot"></div>
          必须随身携带电脑，工作用的文档表格都在电脑里
        </div>
        <div class="commerce-problem--text-item">
          <div class="commerce-problem--text-dot"></div>
          笔记本电脑配置低，运营跑数据分析速度慢
        </div>
        <div class="commerce-problem--text-item">
          <div class="commerce-problem--text-dot"></div>
          软件安装维护麻烦，一不小心就装上全家桶，系统越用越卡
        </div>
      </div>
    </div>
    <div class="commerce-advantage">
      <div class="commerce-advantage__title">
        <img src="@/assets/img/commerce01.png" />
        高效
      </div>
      <div class="commerce-advantage__01">
        <div class="commerce-advantage__01-item">
          <img src="@/assets/img/advantage1.png"/>
          融合管理
        </div>
        <div class="commerce-advantage__01-item">
          <img src="@/assets/img/advantage2.png"/>
          随处运营
        </div>
        <div class="commerce-advantage__01-item">
          <img src="@/assets/img/advantage3.png"/>
          在线文档
        </div>
      </div>
      <div class="commerce-advantage__011">
        <div class="commerce-advantage__011-itembox">
          <div class="commerce-advantage__011-item">
            打通应用和数据，在一个电脑桌面上搞定多家店铺的管理
          </div>
        </div>
        <div class="commerce-advantage__011-itembox">
          <div class="commerce-advantage__011-item">
            任何时间、任何地点、任何终端，都能实现店铺的远程管理
          </div>
        </div>
        <div class="commerce-advantage__011-itembox">
          <div class="commerce-advantage__011-item">
            文档表格云端存储，在线操作并提供头部卖家沉淀的运营模板
          </div>
        </div>
      </div>

      <div class="commerce-advantage__title">
        <img src="@/assets/img/commerce02.png" />
        安全
      </div>
      <div class="commerce-advantage__security">
        <div class="commerce-advantage__security-item">
          <img src="@/assets/img/commercesecurity1.png"/>
          <div class="commerce-advantage__security-item__left">
            店铺安全
            <p>The store security</p>
          </div>
          <img src="@/assets/img/commerceright.png" style="width: 50px; height: 18px;margin-top: 13px;padding-right: 10px"/>
          <img src="@/assets/img/commercedot.png" style="width: 10px; height: 10px;margin: 13px 16px 0 81px;"/>
          <div class="commerce-advantage__security-item__right">
            与电脑完全隔离的环境，纯净的IP地址，护航您的店铺安全
          </div>
        </div>
        <div class="commerce-advantage__security-item">
          <img src="@/assets/img/commercesecurity2.png"/>
          <div class="commerce-advantage__security-item__left">
            账号安全
            <p>Account security</p>
          </div>
          <img src="@/assets/img/commerceright.png" style="width: 50px; height: 18px;margin-top: 13px;padding-right: 10px"/>
          <img src="@/assets/img/commercedot.png" style="width: 10px; height: 10px;margin: 13px 16px 0 81px;"/>
          <div class="commerce-advantage__security-item__right">
            店铺账号自动登录，不用记忆，不用下发，不会登错
          </div>
        </div>
        <div class="commerce-advantage__security-item">
          <img src="@/assets/img/commercesecurity3.png"/>
          <div class="commerce-advantage__security-item__left">
            VPS安全
            <p>VPS security</p>
          </div>
          <img src="@/assets/img/commerceright.png" style="width: 50px; height: 18px;margin-top: 13px;padding-right: 10px"/>
          <img src="@/assets/img/commercedot.png" style="width: 10px; height: 10px;margin: 13px 16px 0 81px;"/>
          <div class="commerce-advantage__security-item__right">
            存量店铺用的VPS可以安全无风险的托管到平台上使用
          </div>
        </div>
      </div>

      <div class="commerce-advantage__title">
        <img src="@/assets/img/commerce03.png" />
        智能
      </div>
      <div class="commerce-advantage__02">
        <div class="commerce-advantage__02-item">
          <div class="commerce-advantage__02-item--title">
            <img src="/assets/img/commerceintelligence1.png"/>
            智能算力
          </div>
          基于云计算提供强大的智能算力引擎，极速完成运营数据分析
        </div>
        <div class="commerce-advantage__02-item">
          <div class="commerce-advantage__02-item--title">
            <img src="/assets/img/commerceintelligence1.png"/>
            智能账号
          </div>
          智能化的账号托管、匹配和授权，确保是正确的人在登正确的店铺账号
        </div>
        <div class="commerce-advantage__02-item">
          <div class="commerce-advantage__02-item--title">
            <img src="/assets/img/commerceintelligence1.png"/>
            云端一体
          </div>
          云端一体的智能应用管理，软件即点即用，无病毒、免运维，电脑更整洁
        </div>
      </div>
    </div>
    <div class="commerce-bottom">
      <div class="commerce-problem--box">
        方案比较
      </div>
      <div class="commerce-bottom__table">
        <div class="commerce-bottom__table-header">
          <div class="commerce-bottom__table-header1">对比项</div>
          <div class="commerce-bottom__table-header2">数影</div>
          <div class="commerce-bottom__table-header3">VPS</div>
          <div class="commerce-bottom__table-header4">超级浏览器</div>
        </div>
        <div class="commerce-bottom__table-center">
          <div class="commerce-bottom__table-center__list" v-for="(item, index) in list" :key="item.contrast">
            <div class="commerce-bottom__table-center__list-item1">
              {{item.contrast}}
            </div>
            <div class="commerce-bottom__table-center__list-item2 commerce-bottom__table-center__list-action">
              <img src="@/assets/img/true.png" style="width: 32px"/>
            </div>
            <div class="commerce-bottom__table-center__list-item3" >
              <div class="commerce-bottom__table-center__list-item3--gang" v-if="index === 3"></div>
              <img :src="item.vps+ '.png'" :style="item.vps ? 'width: 32px' : 'width: 22px'" v-else/>
            </div>
            <div class="commerce-bottom__table-center__list-item4">
              <img :src="item.llq+ '.png'" :style="item.llq ? 'width: 32px' : 'width: 22px'"/>
            </div>
          </div>
        </div>
      </div>
      <div class="commerce-bottom__aboutme">
        <div class="commerce-bottom__aboutme-text">
          <div class="commerce-bottom__aboutme-text__title">
            <img src="@/assets/img/commercegang.png"/>
            联系我们
          </div>
          <div class="commerce-bottom__aboutme-text__center">
            <img src="@/assets/img/phone1.png"/>
            18069895767
          </div>
          <div class="commerce-bottom__aboutme-text__center">
            <img src="@/assets/img/email1.png"/>
            yinghua.cyh@dsphere.cn
          </div>
          <div class="commerce-bottom__aboutme-text__center">
            <img src="@/assets/img/addwi.png"/>
            杭州市余杭区贝达梦工场D座610
          </div>
        </div>
        <div class="commerce-bottom__aboutme-img">
          <img src="@/assets/img/caoer.png"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Header from '@/components/header.vue'
export default {
  components: {
    Header
  },
  data () {
    return {
      list: [{
        contrast: '完全的IP地址',
        my: true,
        vps: false,
        llq: true, 
      }, {
        contrast: '独立的店铺环境',
        my: true,
        vps: true,
        llq: false, 
      }, {
        contrast: '店铺智能安全检测',
        my: true,
        vps: false,
        llq: false, 
      }, {
        contrast: '安全托管现有店铺',
        my: true,
        vps: '-',
        llq: false, 
      }, {
        contrast: '店群融合管理',
        my: true,
        vps: false,
        llq: true, 
      }, {
        contrast: '在线文档协作',
        my: true,
        vps: false,
        llq: false, 
      }, {
        contrast: '店铺账号自动登录',
        my: true,
        vps: false,
        llq: true, 
      }, {
        contrast: '店铺账号防登错',
        my: true,
        vps: false,
        llq: false, 
      }, {
        contrast: '与VPN同时使用',
        my: true,
        vps: true,
        llq: false, 
      }, {
        contrast: '1对1店铺安全顾问',
        my: true,
        vps: false,
        llq: false, 
      }]
    }
  }
}
</script>

<style lang="less">
.commerce {
  background: #fff;
}
.commerce-top {
  background: #ecf2fc url('/commercetop.png') no-repeat top right;
  background-size: 600px 500px;
  height: 600px;
}
.commerce-topBox {
  padding: 38px 0 0;
  width: 1200px;
  height: 600px;
  margin: 0 auto;
}
.commerce-top--box {
  display: flex;
}
.commerce-top__left {
  flex: 1;
}
.commerce-top__right {
  margin-left: 140px;
  margin-right: 48px;
  width: 429px;
  text-align: right;
  img {
    width: 202px;
  }
  p {
    margin-top: 14px;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 3px;
  }
}
.commerce-mil--box {
  width: 1200px;
  margin: 100px auto 49px;
  position: relative;
  .commerce-mil--box__span {
    position: absolute;
    display: flex;
    align-items: center;
    bottom: 12px;
    left: 730px;
    font-size: 42px;
    color: #011847;
    line-height: 42px;
    font-family: Alibaba-PuHuiTi-R, Alibaba-PuHuiTi;
    letter-spacing: 1px;
  }
  .commerce-mil--box__span-gang {
    margin: 0 15px; 
    width: 4px;
    height: 22px;
    background: #011847;
    border-radius: 3px;
    opacity: 0.8;
  }
}
.commerce-ps--box {
  display: flex;
  align-items: center;
  width: 1200px;
  margin: 0 auto;
  font-size: 24px;
  font-weight: normal;
  color: #011847;
  span {
    padding: 0 12px;
    font-size: 28px;
    font-weight: 500;
    color: #0756F0;
  }
}
.commerce-ps--box__gang {
  margin-right: 14px;
  width: 6px;
}
.commerce-ps--button {
  margin-top: 48px;
  button {
    width: 138px;
    height: 48px;
    background: #0756F0;
    border-radius: 8px;
    font-size: 26px;
    font-weight: 500;
    color: #FFFFFF;
    line-height: 48px;
  }
}
.commerce-problem--box {
  width: 1200px;
  height: 114px;
  margin: 60px auto 32px;
  padding-left: 84px;
  background: url('/commerceproblem.png') no-repeat top left;
  background-size: 1200px;
  font-size: 42px;
  font-weight: 600;
  color: #0E44AD;
  line-height: 114px;
  letter-spacing: 1px;
}
.commerce-next {
  margin-bottom: 80px;
}
.commerce-problem--list {
  display: flex;
  justify-content: center;
  width: 1200px;
  margin: 0 auto 40px;
  font-size: 32px;
  font-weight: 500;
  line-height: 44px;
  color: #FFFFFF;
  letter-spacing: 6px;
  .commerce-problem--list-item1 {
    margin: 0 85px;
    padding: 50px 47px 0 61px;
    width: 200px;
    height: 200px;
    background: url('/commerceproblem1.png') no-repeat top left;
    background-size: 100% 100%;
  }
  .commerce-problem--list-item2 {
    margin: 0 85px;
    padding: 50px 47px 0 61px;
    width: 200px;
    height: 200px;
    background: url('/commerceproblem2.png') no-repeat top left;
    background-size: 100% 100%;
  }
  .commerce-problem--list-item3 {
    margin: 0 85px;
    padding: 50px 47px 0 61px;
    width: 200px;
    height: 200px;
    background: url('/commerceproblem3.png') no-repeat top left;
    background-size: 100% 100%;
  }
}
.commerce-problem--text {
  width: 1200px;
  height: 331px;
  margin: 0 auto;
  padding: 15px 89px;
  background: url('/commerceprolist.png') no-repeat top left;
  background-size: 100% 100%;
  .commerce-problem--text-item {
    margin: 10px 0;
    font-size: 24px;
    font-weight: normal;
    color: #011847;
    line-height: 39px;
  }
  .commerce-problem--text-dot {
    display: inline-block;
    margin: 0 29px 0 0;
    width: 21px;
    height: 21px;
    border-radius: 100px;
    background: #011847;
    opacity: 0.8;
  }
}
.commerce-advantage {
  width: 1200px;
  margin: 0 auto;
  .commerce-advantage__title {
    font-size: 42px;
    font-family: Alibaba-PuHuiTi-M, Alibaba-PuHuiTi;
    font-weight: normal;
    color: #124AB6;
    line-height: 42px;
    font-weight: 500;
    letter-spacing: 1px;
    img {
      width: 83px;
    }
  }
  .commerce-advantage__01 {
    margin: 55px 0 35px;
    display: flex;
    justify-content: space-between;
    .commerce-advantage__01-item {
      padding-left: 20px;
      width: 368px;
      font-size: 32px;
      line-height: 52px;
      color: #011847;
      img {
        vertical-align: text-bottom;
        width: 52px;
      }
    }
  }
  .commerce-advantage__011 {
    margin: 35px 0 121px;
    display: flex;
    justify-content: space-between;
    .commerce-advantage__011-itembox {
      overflow: hidden;
      width: 368px;
      height: 202px;
      border-radius: 14px;
      background: linear-gradient(#118FF9,#0756F0);
    }
    .commerce-advantage__011-item {
      margin: 6px;
      padding: 42px 20px;
      width: 356px;
      height: 190px;
      background: #EBF2FE;
      border-radius: 14px;
      border: 6px solid transparent;
      border-radius: 14px;
      font-size: 24px;
      font-family: Alibaba-PuHuiTi-R, Alibaba-PuHuiTi;
      font-weight: normal;
      color: #011847;
      line-height: 32px;
      letter-spacing: 1px;
    }
  }
  .commerce-advantage__security {
    margin: 61px 0 120px;
    width: 1200px;
    height: 450px;
    padding: 10px 28px 10px 43px;
    background: url('/commercesecurity.png') no-repeat top left;
    background-size: 100% 100%;
    .commerce-advantage__security-item {
      display: flex;
      justify-content: space-between;
      margin: 48px 0;
      padding-left: 72px;
      img {
        width: 52px;
        height: 52px;
      }
      .commerce-advantage__security-item__left {
        width: 230px;
        font-size: 32px;
        font-family: Alibaba-PuHuiTi-R, Alibaba-PuHuiTi;
        font-weight: normal;
        color: #011847;
        line-height: 32px;
        letter-spacing: 1px;
        p {
          margin-top: 10px;
          font-size: 18px;
          font-weight: normal;
          color: #011847;
          line-height: 18px;
          letter-spacing: 1px;
        }
      }
      .commerce-advantage__security-item__right {
        margin-right: 30px;
        width: 493px;
        font-size: 24px;
        font-weight: normal;
        color: #FFFFFF;
        line-height: 32px;
        letter-spacing: 1px;
      }
    }
  }
  .commerce-advantage__02 {
    margin: 61px 0 120px;
    display: flex;
    justify-content: space-between;
    .commerce-advantage__02-item {
      width: 320px;
      height: 300px;
      background: url('/assets/img/commerceintelligence.png') no-repeat top left;
      background-size: 100% 100%;
      padding: 55px 20px;
      font-size: 24px;
      color: #011847;
      line-height: 40px;
      letter-spacing: 1px;
      .commerce-advantage__02-item--title {
        margin-bottom: 30px;
        margin-left: 10px;
        font-size: 34px;
        font-weight: normal;
        color: #011847;
        line-height: 32px;
        letter-spacing: 1px;
        img {
          width: 35px;
        }
      }
    }
  }
}
.commerce-bottom {
  background: url('/assets/img/commercebottombg.png') no-repeat bottom left;
  background-size: 100% ;
  .commerce-bottom__table {
    width: 1200px;
    margin: 0 auto;
    background: #fff;
    .commerce-bottom__table-header {
      width: 1200px;
      height: 73px;
      background: linear-gradient(270deg, #118FF9 0%, #0756F0 100%);
      border-radius: 14px 14px 0px 0px;
      opacity: 0.8;
      font-size: 28px;
      font-family: Alibaba-PuHuiTi-M, Alibaba-PuHuiTi;
      font-weight: normal;
      color: #FFFFFF;
      line-height: 73px;
      text-align: center;
      div {
        display: inline-block;
      }
      .commerce-bottom__table-header1 {
        width: 360px;
        border-right: 1px solid #FFFFFF;
      }
      .commerce-bottom__table-header2 {
        width: 270px;
        border-right: 1px solid #FFFFFF;
      }
      .commerce-bottom__table-header3 {
        width: 270px;
        border-right: 1px solid #FFFFFF;
      }
      .commerce-bottom__table-header4 {
        width: 280px;
      }
      .commerce-bottom__table-header5 {
        width: 233px;
      }
    }
    .commerce-bottom__table-center {
      background: #EBF2FE;
      border: 1px solid #0756F0;
      text-align: center;
      font-size: 20px;
      font-family: Alibaba-PuHuiTi-R, Alibaba-PuHuiTi;
      font-weight: normal;
      color: #011847;
      line-height: 58px;
      .commerce-bottom__table-center__list {
        border-bottom: 1px solid #0756F0;
        div {
          display: inline-block;
        }
        .commerce-bottom__table-center__list-item1 {
          width: 360px;
          border-right: 1px solid #0756F0;
        }
        .commerce-bottom__table-center__list-item2 {
          width: 270px;
          border-right: 1px solid #0756F0;
        }
        .commerce-bottom__table-center__list-item3 {
          width: 270px;
          border-right: 1px solid #0756F0;
        }
        .commerce-bottom__table-center__list-item3--gang {
          width: 34px;
          height: 1px;
          border: 2px solid rgba(1, 24, 71, 0.7);
          border-radius: 5px;
        }
        .commerce-bottom__table-center__list-item4 {
          width: 280px;
        }
        .commerce-bottom__table-center__list-item5 {
          width: 233px;
        }
        .commerce-bottom__table-center__list-action {
          color: #F96665;
        }
      }
    }
  }
  .commerce-bottom__aboutme {
    width: 1200px;
    margin: 0 auto;
    padding-bottom: 124px;
    display: flex;
    justify-content: space-between;
    .commerce-bottom__aboutme-text {
      padding: 76px 39px 58px;
      width: 700px;
      background: #fff;
      font-size: 24px;
      color: #011847;
      line-height: 33px;
      letter-spacing: 3px;
      .commerce-bottom__aboutme-text__title {
        margin-bottom: 20px;
        font-size: 32px;
        font-weight: 500;
        color: #124AB6;
        line-height: 32px;
        letter-spacing: 1px;
        img {
          width: 12px;
          vertical-align: inherit;
        }
      }
      .commerce-bottom__aboutme-text__center {
        margin: 6px 0;
        img {
          width: 21px;
          vertical-align: inherit;
        }
      }
    }
    .commerce-bottom__aboutme-img {
      margin-top: 60px;
      width: 194px;
      img {
        width: 194px;
      }
    }
  }
}
</style>