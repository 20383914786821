<template>
  <div class="pay"> 

    <div class="pay-banner">
      <a-carousel autoplay arrows effect="fade" dotsClass="security-box-dot">
        <img src="@/assets/img/joinus.png" alt="banner" />
      </a-carousel>
    </div>
    <div class="pay-box">
      <div class="pay-title">人才招聘</div>
    </div>
    <div class="pay-introduce">
      <div class="pay-introduce-top">
        <div>招聘岗位</div>
        <div>薪资待遇</div>
        <div>招聘人数</div>
        <div>工作地点</div>
        <div>发布日期</div>
      </div>
      <div class="pay-introduce-box">
        <div class="pay-introduce--box-title">
          <div style="color: #397bff; font-weight: bold">驻站主管</div>
          <div>8-15K</div>
          <div>若干</div>
          <div>长沙</div>
          <div>2023/05/12</div>
        </div>
        <div class="pay-introduce--box-form">
          <div>
            <p>工作职责：</p>
            <p>1、负责检测站业务对接，团队管理协调和高度工作；</p>
            <p>2、负责APP用户注册推广工作；</p>
            <p>3、负责站点业务周、月、季、年度报表登记汇报；</p>
            <p>4、负责站点区域维修店的拓展与跟进维护工作；</p>
            <p>5、负责站点区域的接车业务派单，管理实施工作；</p>
          </div>
          <div>
            <p>任职要求：</p>
            <p>1、持C1以上驾驶证，驾龄3年以上，有车优先；</p>
            <p>2、能吃苦耐劳，有一定的语言表达能力和沟通能力；</p>
            <p>3、具备一定的业务能力。</p>
          </div>
        </div>
        <div class="pay-contact">
          <p style="font-weight: bold; font-size: 15px">
            简历投递邮箱：hr@laotiechebanghui.com
          </p>
          <p style="font-weight: bold; font-size: 15px">
            联系人:饶先生 18774935656
          </p>
        </div>
      </div>
      <div class="pay-introduce-box">
        <div class="pay-introduce--box-title">
          <div style="color: #397bff; font-weight: bold">业务代表</div>
          <div>6-10K</div>
          <div>若干</div>
          <div>长沙</div>
          <div>2023/05/12</div>
        </div>
        <div class="pay-introduce--box-form">
          <div>
            <p>工作职责：</p>
            <p>1、配合完成检测站业务对接，以及上门接车业务；</p>
            <p>2、APP用户注册推广工作；</p>
            <p>3、站点区域维修店的拓展维护工作；</p>
            <p>4、听从指挥，配合主管完成站点的相关工作；</p>
          </div>
          <div>
            <p>任职要求：</p>
            <p>1、持C1以上驾驶证，驾龄3年以上，有车优先；</p>
            <p>2、能吃苦耐劳，有一定的语言表达能力和沟通能力；</p>
            <p>3、具备一定的业务能力。</p>
          </div>
        </div>
        <div class="pay-contact">
          <p style="font-weight: bold; font-size: 15px">
            简历投递邮箱：hr@laotiechebanghui.com
          </p>
          <p style="font-weight: bold; font-size: 15px">
            联系人:饶先生 18774935656
          </p>
        </div>
      </div>
      <div class="pay-introduce-box">
        <div class="pay-introduce--box-title">
          <div style="color: #397bff; font-weight: bold">文案策划</div>
          <div>5-6K</div>
          <div>若干</div>
          <div>长沙</div>
          <div>2023/05/12</div>
        </div>
        <div class="pay-introduce--box-form">
          <div>
            <p>工作职责：</p>
            <p>1.负责对外宣传稿、新闻稿及软文的撰写；</p>
            <p>
              2.负责微信、微博等媒体平台的选题策划、文章原创与编辑、更新引流
            </p>
            <p>3.负责企业文化宣传资料的整理与定期更新相关工作</p>
            <p>4.创作撰写优质内容，快速响应热点话题，熟练撰写不同类型文章；</p>
          </div>
          <div>
            <p>任职要求：</p>
            <p>
              1、优秀的文字表现能力，新闻学、传播学、中文、经济管理类相关专业优先；
            </p>
            <p>
              2、悉专业创意方法，思维敏捷，洞察力强，文字功底扎实，语言表达能力强；
            </p>
            <p>
              3、熟悉广告创作流程，有敏锐的创作嗅觉和精准的创意表现力，具备独立思考和分析能力，对问题有自己独特的见解；
            </p>
            <p>4、积极的学习和执行能力，能参与活动执行工作；</p>
            <p>5、有公众号，小程序，抖音，视频号经验者优先。</p>
          </div>
        </div>
        <div class="pay-contact">
          <p style="font-weight: bold; font-size: 15px">
            简历投递邮箱：hr@laotiechebanghui.com
          </p>
          <p style="font-weight: bold; font-size: 15px">
            联系人:饶先生 18774935656
          </p>
        </div>
      </div>
    </div>
    <div class="pay-league">
      <div class="pay-box">
        <div class="pay-title">免费加盟</div>
        <div class="pay-league-box">
          <div>
            <span>维修店加盟</span>
            <div>
              <p>
                1、平台设定两公里范围内，平台只选择一家固定合作的维修店，且维修店自主经营。
              </p>
              <p>
                2、平台对加盟商家进行品牌化，规范化统一管理，原材料统一进货，具有强大的竞争优势。
              </p>
              <p>3、平台维修店加盟费全免。</p>
            </div>
          </div>
          <div>
            <span>汽车配件供应商</span>
            <div>
              <p>
                1、长沙市范围内，有意向与平台合作，共同发展的小型汽车配件供货商。
              </p>
              <p>2、有自主配送体系的供应商优先选择。</p>
              <p>3、平台供应商商品上架费用全免。</p>
            </div>
          </div>
          <div>
            <span>车务门店</span>
            <div>
              <p>1、长沙市范围内，有意向与平台合作车检、代驾、车险等业务。</p>
              <p>2、能够提供接送车服务的优先选择。</p>
              <p>3、平台工号开通上架费用全免。</p>
            </div>
          </div>
        </div>
      </div>
    </div> 
  </div>
</template>

<script> 

export default { 
};
</script>

<style lang="less" scoped>
.pay-banner {
  position: relative;
  width: 100%;
  // height: 400px;
  overflow: hidden;
  color: #fff;
}
.pay-box {
  width: 1200px;
  margin: 50px auto 0;
  .pay-title {
    position: relative;
    text-align: center;
    font-size: 30px;
    &::after {
      content: "";
      position: absolute;
      bottom: -10px;
      left: 50%;
      transform: translate(-50%, 0);
      width: 42px;
      height: 4px;
      background: linear-gradient(270deg, #118ff9 0%, #0756f0 100%);
      border-radius: 2px;
    }
  }
}
.pay-introduce {
  width: 1200px;
  margin: 50px auto;
  .pay-introduce-top {
    height: 60px;
    background: #397bff;
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
    display: flex;
    align-items: center;
    div {
      width: 250px;
      text-align: center;
    }
  }
  .pay-introduce-box {
    .pay-introduce--box-title {
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #333333;
      display: flex;
      margin: 20px 0;
      div {
        width: 250px;
        text-align: center;
      }
    }
    .pay-introduce--box-form {
      margin-left: 90px;
      display: flex;
      p{
        font-size: 16px;
      }
      div:nth-child(1n) { 
        width:550px;
      }
    }
    .pay-contact {
      margin: 30px 0 0 90px;
    }
  }
}
.pay-league {
  padding: 50px 0;
  // height: 550px;
  background: #f6f8fb;
  .pay-box {
    margin: 0 auto;
    .pay-league-box {
      margin-top: 50px;
      display: flex;
      justify-content: space-between;
      div {
        width: 380px;
        height: 220px;
        background-size: cover;
        font-size: 36px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #ffffff;
        text-shadow: 0px 5px 5px rgba(0, 0, 0, 0.5);
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
      }
      div:nth-child(1) {
        background-image: url("../../assets/img/mfjm_01.png");
        div {
          display: none;
        }
      }
      div:nth-child(1):hover {
        span:nth-child(1) {
          display: none;
        }
        div {
          padding: 20px;
          display: block;
          background-image: url("../../assets/img/mfjm_04.png");
          p {
            font-size: 14px;
          }
        }
      }
      div:nth-child(2) {
        background-image: url("../../assets/img/mfjm_02.png");
        div {
          display: none;
        }
      }
      div:nth-child(2):hover {
        span:nth-child(1) {
          display: none;
        }
        div {
          padding: 20px;
          display: block;
          background-image: url("../../assets/img/mfjm_04.png");
          p {
            font-size: 14px;
          }
        }
      }
      div:nth-child(3) {
        background-image: url("../../assets/img/mfjm_03.png");
        div {
          display: none;
        }
      }
      div:nth-child(3):hover {
        span:nth-child(1) {
          display: none;
        }
        div {
          padding: 20px;
          display: block;
          background-image: url("../../assets/img/mfjm_04.png");
          p {
            font-size: 14px;
          }
        }
      }
    }
  }
}
</style>
