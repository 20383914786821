<template>
  <div>
    <div class="news-banner">
      <a-carousel autoplay arrows effect="fade" dotsClass="security-box-dot">
        <img src="@/assets/img/xwzx.png" alt="banner" />
      </a-carousel>
    </div>
    <div class="news-detail">
      <div class="title">
        长沙車狩汽车服务公司 (车)和字节跳动(音)综揽全局汽车行业商家聚享会!!!
      </div>
      <img src="@/assets/img/news-xw.jpg" style="width: 800px; height: 410px" />
      <div class="msg">
        6月21日下午，车狩董事长江剑敏在字节跳动总部会议室参与湖南行业综揽全局汽车行业商家聚享会会谈；
        江剑敏指出，汽车服务行业新媒体快速起步，开局良好，湖南汽车行业呈现出蓬勃活力和广阔前景。车狩与字节跳动的深度融合、渗透，建立相互合作、平等互利、共同发展的好朋友好伙伴。从战略高度和长远角度引领和推动湖南汽车服务行业深入发展，把合作的美好愿景变为实实在在的成果，给汽车车主带来更多福祉
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="less" scoped>
.news-banner {
  position: relative;
  width: 100%;
  // height: 400px;
  overflow: hidden;
  color: #fff;
}
.news-detail {
  width: 1200px;
  margin: 50px auto 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  .title {
    font-size: 36px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #222222;
    margin-bottom: 50px;
  }
  .msg {
    width: 800px;
    font-size: 18px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #333333;
  }
}
</style>