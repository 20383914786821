<template>
  <div class="help">
    <div class="help-banner">
      <a-carousel autoplay arrows effect="fade" dotsClass="security-box-dot">
        <img src="@/assets/img/yhbz.png" alt="banner" />
      </a-carousel>
    </div>
    <div class="help-box">
      <div class="help-title">常见问题</div>
      <div class="help-label">
        <a-space>
          <a-button
            @click="selete(0)"
            class="item"
            :type="action == 0 ? 'primary' : ''"
            >车检问答</a-button
          >
          <a-button
            @click="selete(1)"
            class="item"
            :type="action == 1 ? 'primary' : ''"
            >保险问答</a-button
          >
          <a-button
            @click="selete(2)"
            class="item"
            :type="action == 2 ? 'primary' : ''"
            >养车知识</a-button
          >
          <a-button
            @click="selete(3)"
            class="item"
            :type="action == 3 ? 'primary' : ''"
            >平台问答</a-button
          >
        </a-space>
      </div>
    </div>
    <div class="help-problem" v-for="(v, i) in list" :key="i">
      <div v-if="action === 1">
        <img src="@/assets/img/baoxian.jpg" alt="" srcset="" />
      </div>
      <div v-else>
        <div class="problem">
          <div class="help-problem-label" @click="check(v)">
            <div class="q">Q</div>
            <div class="title">{{ v.title }}</div>
            <div class="icon">
              <a-icon v-show="!v.check" type="down"></a-icon>
              <a-icon v-show="v.check" type="up"></a-icon>
            </div>
          </div>
        </div>
        <div class="answer" v-show="v.check">
          <div class="q a">A</div>
          <div class="val">
            <p v-for="(a, ii) in v.answer" :key="ii">{{ a.p }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import list1 from "./list1.json";
import list2 from "./list2.json";
import list3 from "./list3.json";
import list4 from "./list4.json";
export default {
  data() {
    return {
      list: list1,
      list1,
      list2,
      list3,
      list4,
      action: 0,
    };
  },
  methods: {
    selete(e) {
      this.list = [];
      this.list = this.action = e;
      switch (e) {
        case 0:
          this.list = list1;
          break;
        case 1:
          // this.list = list2;
          break;
        case 2:
          this.list = list3;
          break;
        case 3:
          this.list = list4;
          break;
        default:
          this.list = list1;
          break;
      }
    },
    check(v) {
      v.check = !v.check;
    },
  },
};
</script>

<style lang="less" scoped>
.help-banner {
  position: relative;
  width: 100%;
  // height: 400px;
  overflow: hidden;
  color: #fff;
}
.help-box {
  width: 1200px;
  margin: 100px auto 50px;
  //   padding: 50px 0;
  .help-title {
    position: relative;
    text-align: center;
    font-size: 30px;
    &::after {
      content: "";
      position: absolute;
      bottom: -10px;
      left: 50%;
      transform: translate(-50%, 0);
      width: 42px;
      height: 4px;
      background: linear-gradient(270deg, #118ff9 0%, #0756f0 100%);
      border-radius: 2px;
    }
  }
  .help-label {
    display: flex;
    justify-content: center;
    margin: 50px 0 0;
  }
}
.help-problem {
  //   margin-top: 10px;
  
  display: flex;
    justify-content: center;
  width: 100%;
  .problem {
    width: 100%;
    height: 100px;
    background: #f6f8fb;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .answer {
    width: 1200px;
    margin: 10px auto 0;
    display: flex;
    // align-items: center;
    .val {
      margin: 0 20px;
      p {
        width: 1120px;
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #222222;
      }
    }
  }
  .q {
    width: 40px;
    height: 40px;
    background: #397bff;
    border-radius: 5px;
    font-size: 20px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .a {
    background-color: #afafaf;
  }
  .help-problem-label {
    width: 1200px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    position: relative;
    cursor: pointer;

    .title {
      font-size: 20px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #222222;
      margin-left: 20px;
    }
    .icon {
      position: absolute;
      right: 0;
    }
  }
}
</style>