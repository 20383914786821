<!-- //介绍 -->
<template>
  <div class="introduce">
    <div class="introduce-banner">
      <a-carousel autoplay arrows effect="fade" dotsClass="security-box-dot">
        <img src="@/assets/img/lxwm.png" alt="banner" />
      </a-carousel>
    </div>

    <div class="introduce-box">
      <div class="introduce-title">关于我们</div>
      <div class="introduce-introduce">
        <div class="firm">
          <div class="item">
            <div class="item-intro">
              <span>企业</span>
              <span>简介</span>
              <span>company introduction</span>
            </div>
            <div class="item-msg">
              <div>长沙車狩汽车服务有限公司</div>
              <div style="line-height: 25px">
                历经3年，已经发展为集软件研发、年检、保险、保养于一体的创新型、现代化汽车综合平台；呈现出一城五区一县十二个年检服务网点。
                凭借雄厚的开发实力、精准的服务定位、稳定的服务网络和优质的服务团队，公司赢得了合作单位与客户的普遍认同。秉承锐意创新、敢于担当的企业精神，我们洞察客户深层需求，追求市场发展前沿，打造高水准高品质的车后市场消费生态系统；弘扬“优质、优价、共赢”的核心企业文化，努力践行对客户的承诺，着力将企业打造成为卓越的线上线下集成服务商。
              </div>
            </div>
          </div>
          <div class="item">
            <img src="../../assets/img/gsjstp.png" alt="公司简介" />
          </div>
        </div>
        <div class="platform">
          <div class="item">
            <img src="../../assets/img/ptjjtp.jpg" alt="平台简介" />
          </div>
          <div class="item">
            <div class="item-intro">
              <span>平台</span>
              <span>简介</span>
              <span>Platform Introduction </span>
            </div>
            <div class="item-msg">
              <div>
                “車狩”平台，致力于提升汽车后市场产业服务质量与服务效率。
              </div>
              <div>
                <p style="line-height: 25px">
                  通过整合上游合作单位以及下游终端汽车服务门店，引领“车主与商家互惠互利、共存共赢养车新理念”，助推汽车后市场发展的新方向。
                </p>
                <p style="line-height: 25px">
                  目前平台在湖南各地市合作的车辆检测站和车管所80余家，合作修理厂600余家；年检服务网点12家。为中国车主提供省时、省力、省心、省钱的综合服务平台。
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--  -->
    <div class="introduce-station">
      <div class="station-box">
        <div class="station-item">
          <div class="top">
            <span>16</span>
            <span>个</span>
          </div>
          <div class="msg">长沙六区一县16个检测站</div>
        </div>
        <div class="station-item">
          <div class="top">
            <span>178</span>
            <span>家</span>
          </div>
          <div class="msg">长沙工厂门店178家</div>
        </div>
        <div class="station-item">
          <div class="top">
            <span>10</span>
            <span>万</span>
          </div>
          <div class="msg">服务10万+客户</div>
        </div>
      </div>
    </div>
    <div class="introduce-team">
      <div class="introduce-title">公司创始团队</div>
      <div class="introduce-team-box">
        <div class="item-info">
          <div class="front">
            <div class="name">江剑敏</div>
            <div class="tabs">CEO</div>
            <div class="founder">创始人</div>
          </div>
          <div class="contrary">
            <div>江剑敏</div>
            <div>执行董事</div>
            <div>
              <p>10多年市场运营管理工作经验</p>
              <p>
                连续成功创业者！2015起专注于于汽车后市场服务，先后成立长沙通隆汽车服务部、长沙龙湘汽车服务有限公司、长沙老铁汽车服务有限公司
              </p>
              <p>曾独立完成“平安好车主”湖南省推市与服务。</p>
            </div>
          </div>
        </div>
        <div class="item-info">
          <div class="front">
            <div class="name">王景松</div>
            <div class="tabs">技术总监</div>
            <div class="founder">创始人</div>
          </div>
          <div class="contrary">
            <div>王景松</div>
            <div>技术总监</div>
            <div>
              <p>中国科学技术大学学士，北京航空航天大学硕士</p>
              <p>工信部Linux操作系统认证培训讲师，麒麟软件高级认证工程师;</p>
              <p>
                近20年的软件及信息技术行业经验。曾参与国家“核高基重大专项”主导并完成重庆医改信息化项目、宁夏信息化战略研究项目、中国人民银行业务性能监控平台开发等项目。
              </p>
            </div>
          </div>
        </div>
        <div class="item-info">
          <div class="front">
            <div class="name">饶荣平</div>
            <div class="tabs">运营总监</div>
            <div class="founder">创始人</div>
          </div>
          <div class="contrary">
            <div>饶荣平</div>
            <div>运营总监</div>
            <div>
              <p>广东工业大学电子科学与技术专业</p>
              <p>
                多年技术开发、项目管理经验，对服务行业管家式管理理念有深刻的理解
              </p>
              <p>
                2016年至今深耕汽车服务行业，熟悉汽车后市场业务运作，有丰富的业务资源。
              </p>
            </div>
          </div>
        </div>
        <div class="item-info">
          <div class="front">
            <div class="name">杨帆</div>
            <div class="tabs">商务总监</div>
            <div class="founder">创始人</div>
          </div>
          <div class="contrary">
            <div>杨帆</div>
            <div>商务总监</div>
            <div>
              <p>湖南农业大学财务管理专业</p>
              <p>15年大型企业市场拓展及营销管理经验</p>
              <p>
                有敏锐的市场触觉及资深团队管理经历，积累了丰富市场经验及政府资源。
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- l轮播 -->
    <div class="introduce-credential">
      <div class="introduce-credential-box">
        <img src="../../assets//img/zhengshu_02.png" alt="" />
        <img src="../../assets//img/zhengshu_03.png" alt="" />
        <img src="../../assets//img/zhengshu_01.png" alt="" />
      </div>
    </div>
    <div class="introduce-news">
      <div class="introduce-box">
        <div class="introduce-title">新闻中心</div>
        <div class="introduce-label">
          <a-space>
            <a-button
              @click="selete(0)"
              class="item"
              :type="action == 0 ? 'primary' : ''"
              >行业动态</a-button
            >
            <a-button
              @click="selete(1)"
              class="item"
              :type="action == 1 ? 'primary' : ''"
              >公司新闻</a-button
            >
            <a-button
              @click="selete(2)"
              class="item"
              :type="action == 2 ? 'primary' : ''"
              >活动信息</a-button
            >
            <a-button
              @click="selete(3)"
              class="item"
              :type="action == 3 ? 'primary' : ''"
              >专业知识</a-button
            >
          </a-space>
        </div>
      </div>
      <div class="news" v-for="(v, i) in list" :key="i">
        <template v-if="v.action == action">
          <div class="item" @click="handleNewsDetail">
            <div class="image">
              <img :src="v.url" style="width: 100%; height: 60%" />
            </div>
            <div class="title">{{ v.title }}</div>
            <div class="detail">{{ v.detail }}</div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import img from "@/assets/img/news-datail.png";
export default {
  data() {
    return {
      action: 0,
      list: [
        {
          action: 0,
          title:
            "长沙老铁汽车服务公司 (车)和字节跳动(音)综揽全局汽车行业商家聚享会!!!",
          url: img,
          detail:
            "6月21日下午，车狩董事长江剑敏在字节跳动总部会议室参与湖南行业综揽全局汽车行业商家聚享会会谈； 江剑敏指出，汽车服务行业新媒体快速起步，开局良好，湖南汽车行业呈现出蓬勃活力和广阔前景。车狩与字节跳动的深度融合、渗透，建立相互合作、平等互利、共同发展的好朋友好伙伴。从战略高度和长远角度引领和推动湖南汽车服务行业深入发展，把合作的美好愿景变为实实在在的成果，给汽车车主带来更多福祉。",
        },
        {
          action: 1,
          title:
            "长沙老铁汽车服务公司 (车)和字节跳动(音)综揽全局汽车行业商家聚享会!!!11111111111",
          url: img,
          detail:
            "6月21日下午，车狩董事长江剑敏在字节跳动总部会议室参与湖南行业综揽全局汽车行业商家聚享会会谈； 江剑敏指出，汽车服务行业新媒体快速起步，开局良好，湖南汽车行业呈现出蓬勃活力和广阔前景。车狩与字节跳动的深度融合、渗透，建立相互合作、平等互利、共同发展的好朋友好伙伴。从战略高度和长远角度引领和推动湖南汽车服务行业深入发展，把合作的美好愿景变为实实在在的成果，给汽车车主带来更多福祉。",
        },
        {
          action: 2,
          title:
            "长沙老铁汽车服务公司 (车)和字节跳动(音)综揽全局汽车行业商家聚享会!!!11111111111",
          url: img,
          detail:
            "6月21日下午，车狩董事长江剑敏在字节跳动总部会议室参与湖南行业综揽全局汽车行业商家聚享会会谈； 江剑敏指出，汽车服务行业新媒体快速起步，开局良好，湖南汽车行业呈现出蓬勃活力和广阔前景。车狩与字节跳动的深度融合、渗透，建立相互合作、平等互利、共同发展的好朋友好伙伴。从战略高度和长远角度引领和推动湖南汽车服务行业深入发展，把合作的美好愿景变为实实在在的成果，给汽车车主带来更多福祉。",
        },
        {
          action: 3,
          title:
            "长沙老铁汽车服务公司 (车)和字节跳动(音)综揽全局汽车行业商家聚享会!!!11111111111",
          url: img,
          detail:
            "6月21日下午，车狩董事长江剑敏在字节跳动总部会议室参与湖南行业综揽全局汽车行业商家聚享会会谈； 江剑敏指出，汽车服务行业新媒体快速起步，开局良好，湖南汽车行业呈现出蓬勃活力和广阔前景。车狩与字节跳动的深度融合、渗透，建立相互合作、平等互利、共同发展的好朋友好伙伴。从战略高度和长远角度引领和推动湖南汽车服务行业深入发展，把合作的美好愿景变为实实在在的成果，给汽车车主带来更多福祉。",
        },
      ],
    };
  },
  methods: {
    selete(e) {
      this.action = e;
    },
    handleNewsDetail() {
      this.$router.push("/news");
    },
  },
};
</script>

<style lang="less" scoped>
.introduce-banner {
  position: relative;
  width: 100%;
  // height: 400px;
  overflow: hidden;
  color: #fff;
}
.introduce-title {
  position: relative;
  text-align: center;
  font-size: 30px;
  &::after {
    content: "";
    position: absolute;
    bottom: -10px;
    left: 50%;
    transform: translate(-50%, 0);
    width: 42px;
    height: 4px;
    background: linear-gradient(270deg, #118ff9 0%, #0756f0 100%);
    border-radius: 2px;
  }
}
.introduce-box {
  width: 1200px;
  margin: 100px auto 80px;

  .introduce-introduce {
    display: flex;
    flex-direction: column;
    margin-top: 80px;
    .firm {
      display: flex;
      .item:nth-child(1) {
        padding: 0 50px 0 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        .item-intro {
          span {
            color: #397bff;
            font-size: 24px;
            font-family: Microsoft YaHei;
          }
          span:nth-child(1) {
            font-weight: bold;
            position: relative;
            &::after {
              content: "";
              position: absolute;
              bottom: -10px;
              left: 0;
              width: 60px;
              height: 2px;
              background: linear-gradient(270deg, #118ff9 0%, #0756f0 100%);
              border-radius: 2px;
            }
          }
          span:nth-child(3) {
            font-family: Arial;
            color: #cccccc;
            margin-left: 10px;
          }
        }
        .item-msg {
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #333333;
          div:nth-child(1) {
            font-weight: bold;
            font-size: 16px;
            margin: 20px 0 10px 0;
          }
        }
      }
    }
    .platform {
      display: flex;
      .item:nth-child(2) {
        padding: 0 0 0 50px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        .item-intro {
          span {
            color: #397bff;
            font-size: 24px;
            font-family: Microsoft YaHei;
          }
          span:nth-child(1) {
            font-weight: bold;
            position: relative;
            &::after {
              content: "";
              position: absolute;
              bottom: -10px;
              left: 0;
              width: 60px;
              height: 2px;
              background: linear-gradient(270deg, #118ff9 0%, #0756f0 100%);
              border-radius: 2px;
            }
          }
          span:nth-child(3) {
            font-family: Arial;
            color: #cccccc;
            margin-left: 10px;
          }
        }
        .item-msg {
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #333333;
          div:nth-child(1) {
            font-weight: bold;
            font-size: 16px;
            margin: 20px 0 10px 0;
          }
        }
      }
    }
    .item {
      width: 600px;
      height: 360px;
      img {
        width: 600px;
        height: 360px;
      }
    }
  }
}
.introduce-station {
  width: 1200px;
  margin: 0 auto;
  // height: 200px;
  padding: 50px 0;
  background: #f6f8fb;
  .station-box {
    width: 1200px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    .station-item {
      width: 300px;
      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative;
      .top {
        color: #397bff;
        span:first-child {
          font-size: 60px;
          font-family: Microsoft YaHei;
          font-weight: 400;
        }
      }
      .msg {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #333333;
      }
      &::after {
        content: "";
        position: absolute;
        top: 20px;
        right: 0;
        width: 2px;
        height: 80px;
        background: #f1f1f1;
      }
      &:last-child::after {
        content: none;
      }
    }
  }
}
.introduce-team {
  width: 1200px;
  margin: 100px auto;
  .introduce-team-box {
    margin-top: 50px;
    display: flex;
    justify-content: space-between;
    .item-info {
      width: 282px;
      height: 400px;
      background-size: cover;
      font-size: 36px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #ffffff;
      position: relative;
    }
    .item-info:nth-child(1) {
      background-image: url("../../assets/img/zp1.png");
      .contrary {
        display: none;
      }
      .front {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: -20px;
        display: flex;
        flex-direction: column;
        align-items: center;
      }
      .tabs {
        font-size: 20px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #ffffff;
        margin-bottom: 10px;
      }
      .founder {
        width: 228px;
        height: 50px;
        background: #ffffff;
        box-shadow: 0px 5px 8px 0px rgba(204, 204, 204, 0.2);
        border-radius: 5px;
        font-size: 24px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #222222;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    .item-info:nth-child(1):hover {
      background: #f5f5f5;
      color: #222;
      .founder {
        width: 228px;
        height: 50px;
        background: #397bff;
        box-shadow: 0px 5px 8px 0px rgba(140, 178, 255, 0.2);
        border-radius: 5px;
        font-size: 24px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .contrary {
        display: block;
        div {
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #222222;
          display: flex;
          flex-direction: column;
          align-items: center;
          margin: 0 20px 0px;
        }
        div:nth-child(1) {
          margin-top: 20px;
          font-size: 36px;
          font-weight: bold;
        }
        div:nth-child(2) {
          font-size: 20px;
          margin-bottom: 10px;
        }
      }
      .name,
      .tabs {
        display: none;
      }
    }
    .item-info:nth-child(1n) {
      .contrary {
        display: none;
      }
      .front {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: -20px;
        display: flex;
        flex-direction: column;
        align-items: center;
      }
      .tabs {
        font-size: 20px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #ffffff;
        margin-bottom: 10px;
      }
      .founder {
        width: 228px;
        height: 50px;
        background: #ffffff;
        box-shadow: 0px 5px 8px 0px rgba(204, 204, 204, 0.2);
        border-radius: 5px;
        font-size: 24px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #222222;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    .item-info:nth-child(1n):hover {
      background: #f5f5f5;
      color: #222;
      .founder {
        width: 228px;
        height: 50px;
        background: #397bff;
        box-shadow: 0px 5px 8px 0px rgba(140, 178, 255, 0.2);
        border-radius: 5px;
        font-size: 24px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .contrary {
        display: block;
        div {
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #222222;
          display: flex;
          flex-direction: column;
          align-items: center;
          margin: 0 20px 0px;
        }
        div:nth-child(1) {
          margin-top: 20px;
          font-size: 36px;
          font-weight: bold;
        }
        div:nth-child(2) {
          font-size: 20px;
          margin-bottom: 10px;
        }
      }
      .name,
      .tabs {
        display: none;
      }
    }
    .item-info:nth-child(2) {
      background-image: url("../../assets/img/wzs.png");
    }
    .item-info:nth-child(3) {
      background-image: url("../../assets/img/zp3.jpg");
    }
    .item-info:nth-child(4) {
      background-image: url("../../assets/img/zp4.jpg");
    }
  }
}
.introduce-credential { 
  width: 1200px;
  margin: 0 auto;
  height: 600px;
  background-image: url("../../assets/img/zhengshu_04.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
  .introduce-credential-box {
    width: 1200px;
    height: 500px;
    margin: 0 auto;
    position: relative;
    top: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      transform: scale(0.8);
      margin-left: 0px;
    }
  }
}
.introduce-box {
  width: 1200px;
  margin: 100px auto 50px;
  //   padding: 50px 0;
  .introduce-title {
    position: relative;
    text-align: center;
    font-size: 30px;
    &::after {
      content: "";
      position: absolute;
      bottom: -10px;
      left: 50%;
      transform: translate(-50%, 0);
      width: 42px;
      height: 4px;
      background: linear-gradient(270deg, #118ff9 0%, #0756f0 100%);
      border-radius: 2px;
    }
  }
  .introduce-label {
    display: flex;
    justify-content: center;
    margin: 50px 0 0;
  }
}
.news {
  width: 1200px;
  margin: 0 auto;
  .title { 
    margin-top: 16px;
    font-size: 20px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #222222;
  }
  .detail { 
    margin-top: 14px;
    font-size: 20px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #666;
  }
}
</style>