<template>
  <div>
    <div class="multimedia-banner">
      <a-carousel autoplay arrows effect="fade" dotsClass="security-box-dot">
        <img src="@/assets/img/dmtxc.png" alt="banner" />
      </a-carousel>
    </div>

    <div class="multimedia-attention">
      <div style="width: 380px; height: 260px">
        <video
          src="https://www.laotiechebanghui.cn/vehicle/video/media.mp4"
          width="380px"
          height="260px"
          controls
        ></video>
      </div>
      <div class="tisp">
        <p style="color: #397bff; font-size: 24px">
          你不知道的那些车检注意事项！！！
        </p>
        <p>1、交强险保单在有效期范围内，脱保车辆请先购买车险</p>
        <p>
          2、行驶证原件和紧急停车指示牌 3、车辆无任何改装、车辆外观，灯光完好
        </p>
        <p>3、车辆无任何改装、车辆外观，灯光完好</p>
        <p>
          4、面包车用户，需备灭火器，车窗后视镜无贴膜，或者你还想写一些7788的说明
        </p>
        <p>5、车辆违规已处理</p>
      </div>
    </div>
    <!-- s -->
    <div class="multimedia-video">
      <div class="multimedia-video-box">
        <div class="multimedia-video-title">多媒体视频</div>
      </div>
      <div class="video">
        <div class="video-item" v-for="v in list" :key="v.id">
          <div>
            <video
              :src="v.url"
              width="380px"
              height="260px"
              controls
            ></video>
          </div>
          <div class="video-title">{{ v.title }}</div>
          <div class="video-dateil">{{ v.dateil }}</div>
          <div class="video-time">{{ v.time }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: [
        {
          id: 1,
          title: "你不知道的那些车检注意事项！！！",
          dateil:
            "如何通过交管12123车辆年检预约",
          url: "https://www.laotiechebanghui.cn/vehicle/video/media01.mp4",
          time:'2023-06-16',
        },
        {
          id: 2,
          title: "你不知道的那些车检注意事项！！！",
          dateil:
            "这里是视频的一个简单说明等 简短的介绍简短的介绍简短的介绍简短的介绍",
          url: "https://www.laotiechebanghui.cn/vehicle/video/media02.mp4",
          time:'2023-06-16',
        },
        {
          id: 3,
          title: "你不知道的那些车检注意事项！！！",
          dateil:
            "这里是视频的一个简单说明等 简短的介绍简短的介绍简短的介绍简短的介绍",
          url: "https://www.laotiechebanghui.cn/vehicle/video/media03.mp4",
          time:'2023-06-16',
        },
        {
          id: 4,
          title: "你不知道的那些车检注意事项！！！",
          dateil:
            "这里是视频的一个简单说明等 简短的介绍简短的介绍简短的介绍简短的介绍",
          url: "https://www.laotiechebanghui.cn/vehicle/video/media04.mp4",
          time:'2023-06-16',
        },
        {
          id: 5,
          title: "你不知道的那些车检注意事项！！！",
          dateil:
            "这里是视频的一个简单说明等 简短的介绍简短的介绍简短的介绍简短的介绍",
          url: "https://www.laotiechebanghui.cn/vehicle/video/media05.mp4",
          time:'2023-06-16',
        },
        {
          id: 6,
          title: "你不知道的那些车检注意事项！！！",
          dateil:
            "这里是视频的一个简单说明等 简短的介绍简短的介绍简短的介绍简短的介绍",
          url: "https://www.laotiechebanghui.cn/vehicle/video/media06.mp4",
          time:'2023-06-16',
        },
      ],
    };
  },
};
</script>

<style lang="less" scoped>
.multimedia-banner {
  position: relative;
  width: 100%;
  // height: 400px;
  overflow: hidden;
  color: #fff;
}
.multimedia-attention {
  padding: 100px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  .tisp {
    height: 260px;
    margin-left: 50px;
  }
  p {
    font-size: 16px;
  }
}
.multimedia-video {
  padding: 80px 0;
  background: #f6f8fb;
  .multimedia-video-box {
    // width: 1200px;
    margin: 0px auto;
    .multimedia-video-title {
      position: relative;
      text-align: center;
      font-size: 30px;
      &::after {
        content: "";
        position: absolute;
        bottom: -10px;
        left: 50%;
        transform: translate(-50%, 0);
        width: 42px;
        height: 4px;
        background: linear-gradient(270deg, #118ff9 0%, #0756f0 100%);
        border-radius: 2px;
      }
    }
  }
  .video {
    width: 1200px;
    margin: 50px auto;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    &::after {
      content: "";
      width: 380px;
    }
    .video-item {
      width: 380px;
      margin-bottom: 50px;
      .video-title {
        margin-top: 10px;
        color: #222222;
        font-size: 20px;
      }
      .video-dateil {
        color: #333;
        font-size: 16px;
        flex-wrap: wrap;
      }
      .video-time {
        margin-top: 10px;
        color: #999999;
        font-family: MicrosoftYaHei;
        font-size: 14px;
      }
    }
  }
}
.test {
  width: 380px;
  height: 260px;
  background: #cccccc;
}
</style>