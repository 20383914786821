<template>
  <div class="introduce-news">
    <div class="introduce-box">
      <div class="introduce-title">新闻中心</div>
      <div class="introduce-label">
        <a-space>
          <a-button
            @click="selete(0)"
            class="item"
            :type="action == 0 ? 'primary' : ''"
            >行业动态</a-button
          >
          <a-button
            @click="selete(1)"
            class="item"
            :type="action == 1 ? 'primary' : ''"
            >公司新闻</a-button
          >
          <a-button
            @click="selete(2)"
            class="item"
            :type="action == 2 ? 'primary' : ''"
            >活动信息</a-button
          >
          <a-button
            @click="selete(3)"
            class="item"
            :type="action == 3 ? 'primary' : ''"
            >专业知识</a-button
          >
        </a-space>
      </div>
    </div>
    <div class="news" v-for="(v, i) in list" :key="i">
      <template v-if="v.action == action">
        <div class="image">
          <img :src="v.url" style="width: 100%; height: 60%" />
        </div>
        <div class="title">{{ v.title }}</div>
        <div class="detail">{{ v.detail }}}</div>
      </template>
    </div>
  </div>
</template>

<script>
import img from "@/assets/img/news-datail.png";
export default {
  data() {
    return {
      action: 0,
      list: [
        {
          action: 0,
          title:
            "长沙老铁汽车服务公司 (车)和字节跳动(音)综揽全局汽车行业商家聚享会!!!",
          url: img,
          detail:
            "6月21日下午，车狩董事长江剑敏在字节跳动总部会议室参与湖南行业综揽全局汽车行业商家聚享会会谈； 江剑敏指出，汽车服务行业新媒体快速起步，开局良好，湖南汽车行业呈现出蓬勃活力和广阔前景。车狩与字节跳动的深度融合、渗透，建立相互合作、平等互利、共同发展的好朋友好伙伴。从战略高度和长远角度引领和推动湖南汽车服务行业深入发展，把合作的美好愿景变为实实在在的成果，给汽车车主带来更多福祉。",
        },
        {
          action: 1,
          title:
            "长沙老铁汽车服务公司 (车)和字节跳动(音)综揽全局汽车行业商家聚享会!!!11111111111",
          url: img,
          detail:
            "6月21日下午，车狩董事长江剑敏在字节跳动总部会议室参与湖南行业综揽全局汽车行业商家聚享会会谈； 江剑敏指出，汽车服务行业新媒体快速起步，开局良好，湖南汽车行业呈现出蓬勃活力和广阔前景。车狩与字节跳动的深度融合、渗透，建立相互合作、平等互利、共同发展的好朋友好伙伴。从战略高度和长远角度引领和推动湖南汽车服务行业深入发展，把合作的美好愿景变为实实在在的成果，给汽车车主带来更多福祉。",
        },
        {
          action: 2,
          title:
            "长沙老铁汽车服务公司 (车)和字节跳动(音)综揽全局汽车行业商家聚享会!!!11111111111",
          url: img,
          detail:
            "6月21日下午，车狩董事长江剑敏在字节跳动总部会议室参与湖南行业综揽全局汽车行业商家聚享会会谈； 江剑敏指出，汽车服务行业新媒体快速起步，开局良好，湖南汽车行业呈现出蓬勃活力和广阔前景。车狩与字节跳动的深度融合、渗透，建立相互合作、平等互利、共同发展的好朋友好伙伴。从战略高度和长远角度引领和推动湖南汽车服务行业深入发展，把合作的美好愿景变为实实在在的成果，给汽车车主带来更多福祉。",
        },
        {
          action: 3,
          title:
            "长沙老铁汽车服务公司 (车)和字节跳动(音)综揽全局汽车行业商家聚享会!!!11111111111",
          url: img,
          detail:
            "6月21日下午，车狩董事长江剑敏在字节跳动总部会议室参与湖南行业综揽全局汽车行业商家聚享会会谈； 江剑敏指出，汽车服务行业新媒体快速起步，开局良好，湖南汽车行业呈现出蓬勃活力和广阔前景。车狩与字节跳动的深度融合、渗透，建立相互合作、平等互利、共同发展的好朋友好伙伴。从战略高度和长远角度引领和推动湖南汽车服务行业深入发展，把合作的美好愿景变为实实在在的成果，给汽车车主带来更多福祉。",
        },
      ],
    };
  },
  methods: {
    selete(e) {
      this.action = e;
      console.log(this.action);
    },
  },
};
</script>

<style lang="less" scoped>
.introduce-box {
  width: 1200px;
  margin: 100px auto 50px;
  //   padding: 50px 0;
  .introduce-title {
    position: relative;
    text-align: center;
    font-size: 30px;
    &::after {
      content: "";
      position: absolute;
      bottom: -10px;
      left: 50%;
      transform: translate(-50%, 0);
      width: 42px;
      height: 4px;
      background: linear-gradient(270deg, #118ff9 0%, #0756f0 100%);
      border-radius: 2px;
    }
  }
  .introduce-label {
    display: flex;
    justify-content: center;
    margin: 50px 0 0;
  }
}
.news {
  width: 1200px;
  margin: 0 auto;
  .title {
    // width: 1200px;
    margin-top: 16px;
    font-size: 20px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #222222;
  }
  .detail {
    // width: 0px;
    margin-top: 14px;
    font-size: 20px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #666;
  }
}
</style>